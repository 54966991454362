import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../../seo"

const ProductionPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {slug: {eq: "prod"}}) {
        nodes {
          title
          content
          seoqueries {
            title
            description
            keywords
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

    //SEO THINGS
  const SeoTitle = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.title))
  const SeoDescription = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.description)) 
  const SeoKeywords = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.keywords))
    //END SEO THINGS

  const prodTitle = data.allWpPage.nodes.map(({title}) => (<p className="section-title section-title--green"  dangerouslySetInnerHTML={{ __html: title }} />))
  const featureImage = data.allWpPage.nodes.map(({featuredImage}) => (<img src={featuredImage.node.localFile.childImageSharp.fluid.src } alt="Производство банных чанов в Екатеринбурге" />))
  const prodContent = data.allWpPage.nodes.map(({content}) => (<div  dangerouslySetInnerHTML={{ __html: content }} />))

  return(
      <>
        <Seo 
          title = {SeoTitle}
          description = {SeoDescription}
          keywords = {SeoKeywords.toString()}
        />
      {prodTitle}
      {featureImage}
      {prodContent}
      </>
  )
}

export default ProductionPage 
