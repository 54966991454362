import React from 'react';
import ProductionPage from './production';




const Production = () => {
    return(
        <>
        <ProductionPage />
        </>        
    )
}

export default Production