import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import Layout from "../components/layout"


import Production from "../components/Modules/ProductionPage"


const Proizvodstvo = () => {
    return(
        <Layout>
            <Container>            
                <Row>
                    <Col>
                        <Production />
                    </Col>
                </Row>
            </Container>
        </Layout>
        
    )
}

export default Proizvodstvo